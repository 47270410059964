<template>
	<div class="container">
		<div class="replace-con">
			<div class="replace-top">
				<div class="tip" @click="gameshow=true">玩法介绍</div>
				<div class="tip" @click="$router.push('/ReplacementRecord')">升级记录</div>
			</div>
			<div class="replace-main">
				<div class="gun-con">
					<div class="gun-empty" v-if="XZlist.length==0" @click="storageShow=true">
						<img src="~@/assets/img/gun-empty.png" alt="">
						<span>选择您想要<br />升级的物品</span>
					</div>
					<div class="gun-list" v-else @click="storageShow=true">
						<div class="scroll-con">
							<el-scrollbar style="height:100%;">
								<div class="img-con" v-for="(item,index) in XZlist" :key="index">
									<img :src="item.cover" alt="">
								</div>
							</el-scrollbar>
						</div>
					</div>
					<div class="price">
						<div class="coins"></div>{{ ZongJia.toFixed(2) }}
					</div>
				</div>
				<div class="rate">
					<span>{{ Bili.toFixed(0) }}%</span>
					升级概率
				</div>
				<div class="gun-con">
					<div class="gun-empty" @click="synthesisSshow=true">
						<img v-if="!AdItem.cover" src="~@/assets/img/gun-empty.png" alt="">
						<img v-else :src="AdItem.cover" alt="">
						<span v-if="!AdItem.cover">选择您期望<br />得到的物品</span>
					</div>
					<div class="price" style="justify-content: flex-end;">
						<div class="coins"></div>{{ ZongJia.toFixed(2) }}
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="game-title">幸运饰品</div> -->
		<!-- <div class="rule-con">
			<div class="rule-tit" @click="gameshow=true">
				玩法介绍
				<img src="~@/assets/img/icon-help.png" alt="">
			</div>
		</div>
		<div class="replace-top-con">
			<div class="rate">{{ Bili.toFixed(0) }}%</div>
			<div class="record-tit" @click="$router.push('/ReplacementRecord')">合成记录</div>
		</div>
		<div class="select-con">
			<div class="item-con">
				<div class="item" @click="storageShow=true">
					<img v-if="XZlist.length==0" class="empty" src="~@/assets/img/replace-item-empty.png" alt="">
					<div v-else class="selected-con">
						<div class="scroll-con">
							<el-scrollbar style="height:100%;">
								<div class="list" :class="['number'+XZlist.length]">
									<div class="img-con" v-for="(item,index) in XZlist" :key="index">
										<img :src="item.cover" alt="">
									</div>
								</div>
							</el-scrollbar>
						</div>
						<div class="price">
							<div class="coins"></div>{{ ZongJia.toFixed(2) }}
						</div>
					</div>
				</div>
				<div class="label">选择您想要升级的物品</div>
			</div>
			<div class="item-con">
				<div class="item" @click="synthesisSshow=true">
					<img v-if="!AdItem.cover" class="empty" src="~@/assets/img/replace-item-empty.png" alt="">
					<div v-else class="selected-con">
						<div class="list number1">
							<div class="img-con">
								<img :src="AdItem.cover" alt="">
							</div>
						</div>
						<div class="price">
							<div class="coins"></div>{{ AdItem.bean }}
						</div>
					</div>
				</div>
				<div class="label">选择您期望得到的物品</div>
			</div>
		</div>
		<div class="replace-middle-bg">
			<img src="~@/assets/img/replace-middle-bg.png" alt="">
		</div> -->
		<div class="balance-use-con">
			<div class="label">使用余额</div>
			<el-slider v-model="balanceRate" :show-tooltip="false"></el-slider>
		</div>
		<div class="balance-use-show">
			<div class="price">
				<div class="coins"></div>
				<span>已使用余额</span>
				{{ userBean.toFixed(2) }}
			</div>
			<div class="price">
				<div class="coins"></div>
				<span>总价值</span>
				{{ (userBean+ZongJia).toFixed(2) }}
			</div>
		</div>
		<div class="replace-btn sure" @click="postSynthesis">
			确定合成
		</div>
		<div class="skip-con">
			跳过动画
			<van-switch v-model="checked" size="16px" />
		</div>
		<div class="friend-tit">
			本站所有游戏道具，只能本人取回到游戏内使用，均不允许站内交易及互换
		</div>
		<van-popup v-model="storageShow" position="right" :style="{ width: '100%',height: '100%' }">
			<div class="replace-popup-con">
				<div class="replace-popup-header">
					<div class="icon-return" @click="storageShow=false"></div>
					<img class="gun" src="~@/assets/img/replace-header-gun.png" alt="">
					<div class="right">
						选择升级材料
						<img src="~@/assets/img/replace-header-gun.png" alt="">
					</div>
				</div>
				<div class="scroll-con">
					<el-scrollbar style="height:100%;">
						<div class="gun-list">
							<div class="item" ref="Item" v-for="(item,index) in StorageList" :key="item.id" @click="XZitem(item,index)">
								<div class="alias" v-if="item.dura_alias">{{ item.dura_alias }}</div>
								<div class="img-con">
									<img :src="item.cover" alt="">
								</div>
								<div class="name">{{ item.name }}</div>
								<div class="price">
									<div class="coins"></div>{{ item.bean }}
								</div>
							</div>
						</div>	
					</el-scrollbar>
				</div>
				
				<div class="PageNum">
					<el-pagination background layout="prev, pager, next"
						@current-change="handleCurrentChange"
						:page-size="per_page"
						:total="PageNum">
					</el-pagination>
				</div>
				<div class="replace-btn" @click="storageShow=false">
					确定
				</div>
			</div>
		</van-popup>
		<van-popup v-model="synthesisSshow" position="right" :style="{ width: '100%',height: '100%' }">
			<div class="replace-popup-con">
				<div class="replace-popup-header">
					<div class="icon-return" @click="synthesisSshow=false"></div>
					<img class="gun" src="~@/assets/img/replace-header-gun.png" alt="">
					<div class="right">
						选择期望得到的物品
						<img src="~@/assets/img/replace-header-gun.png" alt="">
					</div>
				</div>
				<div class="search-con">
					<!-- <div class="sort" @click="changeDuraSort()">
						外观
						<img src="~@/assets/img/icon-gun-sort.png" alt="" :class="{'up': order_by_dura=='asc'}">
					</div> -->
					<el-select
						class="sort-select"
						v-model="dura"
						popper-class="toolbar-poper"
						@change="refresh()"
					>
						<el-option label="不限" value=""></el-option>
						<el-option label="崭新出厂" :value="1"></el-option>
						<el-option label="略有磨损" :value="2"></el-option>
						<el-option label="久经沙场" :value="3"></el-option>
						<el-option label="破损不堪" :value="4"></el-option>
						<el-option label="战痕累累" :value="5"></el-option>
						<el-option label="无涂装" :value="0"></el-option>
					</el-select>
					<div class="sort" @click="changeBeanSort()">
						价格
						<img src="~@/assets/img/icon-gun-sort.png" alt="" :class="{'up': order_by_bean=='asc'}">
					</div>
					<input type="text" class="sort-input" placeholder="请输入道具名" v-model="name" @input="refresh()" @change="refresh()">
				</div>
				<div class="scroll-con">
					<el-scrollbar style="height:100%;">
						<div class="gun-list">
							<div class="item" ref="Add" v-for="(item,index) in SynthesisList" :key="item.id" @click="ADitem(item,index)">
								<div class="alias" v-if="item.dura_alias">{{ item.dura_alias }}</div>
								<div class="img-con">
									<img :src="item.cover" alt="">
								</div>
								<div class="name">{{ item.name }}</div>
								<div class="price">
									<div class="coins"></div>{{ item.bean }}
								</div>
							</div>
						</div>
					</el-scrollbar>
				</div>
				
				<div class="PageNum">
					<el-pagination background layout="prev, pager, next" @current-change="Changepage" :page-size="Synthesis_page"
					 :total="SynthesisNum">
					</el-pagination>
				</div>
				<div class="replace-btn" @click="synthesisSshow=false">
					确定
				</div>
			</div>
		</van-popup>
		<van-overlay :show="gameshow">
			<div class="wrapper">
				<div class="rule-popup-con">
					<div class="rule-popup-header">
						玩法介绍
						<div class="close" @click="gameshow = false"></div>
					</div>
					<div class="rule-popup-main" style="overflow: auto;">
						<div class="rule-popup-content" v-html="publicConfig.desc_synthe"></div>
					</div>
				</div>
			</div>
		</van-overlay>
		<van-overlay :show="isShow">
			<div class="wrapper" v-if="isShow">
				<div class="replace-show-box">
					<div class="main">
						<div class="img-con">
							<img :src="HCitem.cover" alt="">
						</div>
						<div class="name">{{ HCitem.name }}</div>
						<div class="price">
							<div class="coins"></div>{{ HCitem.bean }}
						</div>
					</div>
					<div class="continue-btn" @click="GetIshow">继续合成</div>
				</div>
			</div>
		</van-overlay>

		<van-overlay :show="netShow">
			<div class="wrapper">
				<div class="replace-show-box fail">
					<div class="main"></div>
					<div class="continue-btn" @click="GetIshow">继续合成</div>
				</div>
			</div>
		</van-overlay>
		<van-overlay :show="animateShow">
			<div class="wrapper">
				<div class="animate-img">
					<img src="~@/assets/img/replace-loading.webp" alt="">
				</div>
			</div>
		</van-overlay>
	</div>
</template>

<script>
	import { Storage, Synthesis_list, Synthesis } from '@/network/api.js'
	import {mapState} from 'vuex'
	import { Decimal } from 'decimal.js'
	export default {
		name: 'Replacement',
		data() {
			return {
				StorageList:[],
				PageNum:0,
				per_page:0,
				page:1,

				SynthesisList:[],
				SynthesisNum:0,
				Synthesis_page:0,
				S_page:1,

				XZlist:[],

				AdItem:{},
				show:false,

				ZongJia:0,
				Bili:1,

				HCitem:{},
				isShow:false,
				sty:'',
				gameshow:false,
				netShow:false,
				Z_key:false,
				checked:false,
				storageShow: false,
				synthesisSshow: false,
				animateShow: false,
				balanceRate: 0,
				dura: '',
				order_by_bean: 'asc',
				name: '',
				sortType: 'bean'
			}
		},
		computed:{
			...mapState(['publicConfig','User']),
			userBean(){
				let bean = 0
				if(this.AdItem.bean){
					let user_bean = parseFloat(this.User.bean)
					let ad_bean = parseFloat(this.AdItem.bean)
					let last_bean = ad_bean*0.75 - this.ZongJia
					if(last_bean<0){
						last_bean = 0
					}
					bean = last_bean*this.balanceRate/100
					return bean>user_bean?user_bean:bean
				}else{
					return 0
				}
			},
		},
		created() {
			this.GetStorage(),
			this.GetSynthesis_list()
		},

		methods:{
			changeDuraSort(){
				this.refresh()
			},
			changeBeanSort(){
				if(this.order_by_bean=='asc'){
					this.order_by_bean = 'desc'
				}else{
					this.order_by_bean = 'asc'
				}
				this.refresh()
			},
			refresh(){
				this.page = 1
				this.GetSynthesis_list()
			},
			//装备列表
			GetStorage(){
				let params = {
					page: this.page,
					status: '0'
				}
				Storage(params).then((res) =>{
					// console.log(res.data.data)
					this.StorageList = res.data.data.data
					this.PageNum = Number(res.data.data.total)
					this.per_page = Number(res.data.data.per_page)
					this.$nextTick(() => {
						// for(let i = 0; i < this.StorageList.length; i++){
						// 	console.log(this.$refs.Item)
						// 	// this.$refs.Item[i].style.border = "1px solid transparent"
						// }
						this.$forceUpdate()
						for(let i = 0; i < this.XZlist.length; i++){
							var index = this.StorageList.findIndex(item=>item.id == this.XZlist[i].id)
							if(index>-1){
								this.StorageList[index].Select = true
								this.$refs.Item[i].style.border = "1px solid #E9B10E"
							}
						}
					})
				})
			},
			//装备列表分页
			handleCurrentChange(val){
				// console.log(val)
				this.page = val
				this.GetStorage()
			},
			//合成装备列表
			GetSynthesis_list(){
				let params = {
					page: this.S_page,
					name: this.name,
					order_by_bean: this.order_by_bean,
					dura: this.dura
				}
				Synthesis_list(params).then((res)=>{
					// console.log(res.data.data.data)
					this.SynthesisList = res.data.data.data
					if(this.AdItem.id){
						let index = this.SynthesisList.findIndex(item=>item.id == this.AdItem.id)
						if(index>-1){
							this.SynthesisList[index].Select = true
							this.$nextTick(()=>{
								this.$refs.Add[index].style.border = "1px solid #E9B10E"
							})
							
						}
					}
					this.SynthesisNum = Number(res.data.data.total)
					this.Synthesis_page = Number(res.data.data.per_page)
				})
			},
			//合成装备列表分页
			Changepage(val){
				this.S_page = val
				this.GetSynthesis_list()
			},

			// 选中
			XZitem(item,index){
				// console.log(item,index)
				if (item.Select) {
					delete item.Select
					this.$refs.Item[index].style.border = "1px solid transparent"
					let Index = this.XZlist.findIndex((v)=>{
						return v.id == item.id
					})
					// console.log(index)
					this.XZlist.splice(Index,1)
				} else {
					let key = "Select";
					let value = true
					item[key] = value
					this.$refs.Item[index].style.border = "1px solid #E9B10E"
					this.XZlist.push(item)
				}
			},
			//删除选中
			SCitem(item,index){
				// console.log(item)
				// this.XZlist.splice(index,1)
				let Index = this.StorageList.findIndex((v)=>{
					return v.id == item.id
				})
				// console.log(index)
				this.$refs.Item[Index].style.border = "1px solid transparent"
				// console.log(this.$refs.Item[Index])

			},

			// 选中
			ADitem(item,index){
				if (item.Select) {
					delete item.Select
					this.$refs.Add[index].style.border = "1px solid transparent"
					this.AdItem = {}
				} else {
					for(let i = 0; i<this.SynthesisList.length;i++){
						this.$refs.Add[i].style.border = "1px solid transparent"
						delete this.SynthesisList[i].Select
					}
					let key = "Select";
					let value = true
					item[key] = value
					this.$refs.Add[index].style.border = "1px solid #E9B10E"
					this.AdItem = item
					// console.log(item)
				}



			},

			//删除
			SCAditem(){
				let Index = this.SynthesisList.findIndex((v)=>{
					return v.id == this.AdItem.id
				})
				this.$refs.Add[Index].style.border = "1px solid transparent"
				this.AdItem = {}
			},

			//合成
			postSynthesis(){
				if(this.Bili<5){
					this.$notify({
						type: 'error',
						message: '比例至少大于5%'
					})
					return false
				}
				if((this.userBean+this.ZongJia)>this.AdItem.bean){
					this.$confirm('合成投入总价值大于目标饰品价值，是否继续合成?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning',
						center: true
					}).then(() => {
						this.postSynthesis1()
					}).catch(() => {
						
					});
				}else{
					this.postSynthesis1()
				}
			},
			postSynthesis1(){
				let data = []
				for(let i = 0 ; i<this.XZlist.length; i++){
					data.push({
						id:this.XZlist[i].id
					})
				}
				let params = {
					data,
					out_id: this.AdItem.id,
					user_bean: this.userBean.toFixed(2)
				}
				Synthesis(params).then((res)=>{
					let sty = ''
					let step = 0
					if(!this.checked){
						this.animateShow=true
						step = 3000
					}
					this.sty = sty
					// console.log(res)
					if(res.data.data){
						this.HCitem = res.data.data
						setTimeout(()=>{
							this.isShow = true
							this.XZlist = []
							this.GetStorage()
							this.AdItem = {}
							this.animateShow = false
							for(let i = 0; i < this.StorageList.length; i++){
								this.$refs.Item[i].style.border = "1px solid transparent"
							}
							for(let i = 0; i<this.SynthesisList.length;i++){
								this.$refs.Add[i].style.border = "1px solid transparent"
								delete this.SynthesisList[i].Select
							}
							this.sty = ''
							this.Bili = 1
						},step)
					}else{
						setTimeout(()=>{
							this.animateShow=false
							this.netShow = true
							this.XZlist = []
							this.GetStorage()
							this.AdItem = {}
							for(let i = 0; i < this.StorageList.length; i++){
								this.$refs.Item[i].style.border = "1px solid transparent"
							}
							for(let i = 0; i<this.SynthesisList.length;i++){
								this.$refs.Add[i].style.border = "1px solid transparent"
								delete this.SynthesisList[i].Select
							}
							this.sty = ''
						},step)
					}

				})
			},
			GetIshow(){
				this.isShow = false
				this.netShow = false
			},

			YiJian(){
				if(this.Z_key == false){
					let key = "Select";
					let value = true
					for(let i = 0; i < this.StorageList.length; i++){
						this.StorageList[i][key] = value
						this.$refs.Item[i].style.border = "1px solid #E9B10E"
						this.XZlist.push(this.StorageList[i])
					}
					this.Z_key = true
				}else{
					for(let i = 0; i < this.StorageList.length; i++){
						delete this.StorageList[i].Select
						this.$refs.Item[i].style.border = "1px solid transparent"
						this.XZlist = []
					}
					this.Z_key = false
				}
			},
			countRate(){
				if(this.AdItem.bean){
					// let Bili = Math.floor((this.userBean+this.ZongJia)/parseFloat(this.AdItem.bean)*100)
					let xx = new Decimal(this.userBean+this.ZongJia)
					let yy = new Decimal(parseFloat(this.AdItem.bean))
					let Bili = xx.div(yy).toNumber()*100
					this.Bili = Bili > 75 ? 75 : Bili < 1 ? 1 : Bili
				}else{
					this.Bili = 1
				}
			}
		},
		watch:{
			userBean(){
				this.countRate()
			},
			AdItem(v){
				this.countRate()
			},
			XZlist(v){
				let ZongJia = 0
				// console.log(v)
				for(let i = 0; i<v.length;i++){
					ZongJia += Number(v[i].bean)
				}
				this.ZongJia = ZongJia
				this.countRate()
			},
		}
	}
</script>

<style lang="scss">
	@import "@/assets/css/base.scss";
	.rule-con{
		@include Scenter;
		justify-content: flex-end;
		padding-right: 15px;
	}
	.rule-tit{
		@include Scenter;
		img{
			width: 10px;
			height: 10px;
			margin-left: 2px;
		}
	}
	.replace-top-con{
		@include Hcenter;
		height: 213px;
		background: url(~@/assets/img/replace-top-bg.png);
		background-size: 100% 100%;
		padding-top: 110px;
		position: relative;
		.rate{
			background: var(--logo, linear-gradient(180deg, #448EFD 0%, #44FDE7 100%));
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			font-size: 32px;
			line-height: 40px;
			height: 40px;
			font-weight: bold;
		}
		.record-tit{
			position: absolute;
			right: 15px;
			top: 29px;
			background: var(--logo, linear-gradient(180deg, #448EFD 0%, #44FDE7 100%));
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			font-size: 12px;
			cursor: pointer;
		}
	}
	.select-con{
		@include Hcenter;
		gap: 30px;
		margin-top: 17px;
		.item-con{
			.label{
				text-align: center;
				margin-top: 16px;
				font-size: 12px;
				color: #bbb;
			}
		}
		.item{
			width: 155px;
			height: 127px;
			background: url(~@/assets/img/replace-item-bg.png);
			background-size: 100% 100%;
			@include HScenter;
			.empty{
				width: 95px;
				height: 50px;
			}
			
			.selected-con{
				.scroll-con{
					width: 102px;
					height: 72px;
					margin: 0 auto;
					.el-scrollbar__wrap{
						overflow-x: hidden;
					}
				}
				.list{
					display: flex;
					flex-wrap: wrap;
					gap: 12px;
					.img-con{
						width: 45px;
						height: 30px;
					}
					&.number1{
						justify-content: center;
						align-items: center;
						.img-con{
							width: 98px;
							height: 66px;
						}
					}
					&.number2{
						align-items: center;
						.scroll-con{
							width: 130px;
						}
						.img-con{
							width: 59px;
							height: 40px;
						}
					}
				}
				.price{
					@include HScenter;
					font-size: 14px;
					font-weight: bold;
					margin-top: 10px;
					.coins{
						width: 20px;
						height: 20px;
						margin-right: 9px;
					}
				}
			}
		}
	}
	.replace-middle-bg{
		img{
			width: 100%;
		}
	}
	.replace-btn{
		width: 257px;
		height: 51px;
		background-image: url(~@/assets/img/open-btn-bg.png);
		background-size: 100% 100%;
		margin: 0 auto;
		font-size: 14px;
		font-weight: bold;
		@include HScenter;
		cursor: pointer;
		&.sure{
			width: 228px;
			height: 49px;
			background-image: url(~@/assets/img/replace-btn.png);
		}
	}
	.skip-con{
		@include HScenter;
		font-size: 12px;
		color: #999;
		margin-top: 16px;
		.van-switch{
			border: none;
			margin-left: 6px;
		}
		.van-switch--on{
			background: var(--logo, linear-gradient(180deg, #448EFD 0%, #44FDE7 100%));
		}
	}
	.friend-tit{
		background: #131313;
		font-size: 12px;
		color: #999;
		text-align: center;
		padding: 12px 30px;
		margin: 20px 15px 0;
	}
	.replace-show-box{
		width: 351px;
		height: 345px;
		padding-top: 20px;
		// background: #1D1D28;
		background-image: url(~@/assets/img/replace-result-success.png);
		background-size: 100% 100%;
		&.fail{
			background-image: url(~@/assets/img/replace-result-fail.png);
			.main{
				height: 240px;
				// background-image: url(~@/assets/img/replace-result-fail.png);
			}
		}
		.main{
			width: 100%;
			height: 249px;
			@include Scenter;
			flex-direction: column;
			padding-top: 60px;
			.img-con{
				width: 160px;
				height: 108px;
			}
			.name{
				margin-bottom: 4px;
				@include wordhide;
				max-width: 90%;
			}
			.price{
				@include Scenter;
				font-size: 15px;
				font-weight: bold;
				.coins{
					width: 20px;
					height: 20px;
					margin-right: 10px;
				}
			}
		}
		.continue-btn{
			width: 222px;
			height: 40px;
			background: var(--logo, linear-gradient(180deg, #448EFD 0%, #44FDE7 100%));
			text-shadow: 0px 2px 4px #3A6EB5;
			@include HScenter;
			margin: -8px auto 0;
			cursor: pointer;
		}
	}
	.replace-popup-con{
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		background: #15161B;
		overflow: hidden;
		padding-bottom: 12px;
		.replace-popup-header{
			width: 375px;
			margin: 0 auto;
			height: 75px;
			background: #1D1D28;
			border-bottom: 1px solid #75828D;
			@include Scenter;
			padding: 0 26px 0 14px;
			.icon-return{
				width: 24px;
				height: 24px;
				background-size: 100% 100%;
				background-image: url(~@/assets/img/icon-return.png);
				cursor: pointer;
				margin-right: 10px;
			}
			.gun{
				width: 95px;
			}
			.right{
				margin-left: auto;
				margin-top: 10px;
				@include Scenter;
				img{
					width: 14px;
					height: 17px;
				}
			}
		}
		.search-con{
			flex-shrink: 0;
			display: flex;
			gap: 16px;
			flex-wrap: wrap;
			width: 350px;
			margin: 0 auto;
			padding-top: 12px;
			.sort{
				flex: 1;
				background: #1D1D28;
				height: 32px;
				padding: 0 14px;
				@include Scenter;
				justify-content: space-between;
				cursor: pointer;
				img{
					width: 14px;
					&.up{
						transform: rotateZ(180deg);
					}
				}
			}
			.sort-select{
				flex: 1;
				background: #1D1D28;
				height: 32px;
				.el-input{
					width: 100%;
					height: 100%;
					background: none;
					border: none;
					input{
						width: 100%;
						height: 100%;
						background: none;
						border: none;
						color: #fff;
					}
					.el-input__icon{
						background: url(~@/assets/img/icon-gun-sort.png) no-repeat center center;
						background-size: 14px auto;
						&::before{
							display: none;
						}
					}
				}
			}
			.sort-input{
				background-image: url(~@/assets/img/search-bg.png);
				background-size: 100% 100%;
				height: 32px;
				width: 100%;
				flex-shrink: 0;
				text-align: center;
			}
		}
		.scroll-con{
			flex: 1;
			overflow: hidden;
			width: 350px;
			margin: 10px auto;
			.el-scrollbar__wrap{
				overflow-x: hidden;
			}
		}
		.gun-list{
			display: flex;
			flex-wrap: wrap;
			gap: 8px;
			.item{
				width: 171px;
				height: 174px;
				@include HScenter;
				flex-direction: column;
				position: relative;
				border: 1px solid #3C3C4B;
				background: #1D1D28;
				&.selected{
					border-color: #FFB800;
				}
				.alias{
					position: absolute;
					right: 4px;
					top: 4px;
					height: 20px;
					line-height: 20px;
					padding-right: 4px;
					background: #FFB800;
					color: #000;
					font-size: 12px;
					&::before{
						content: '';
						position: absolute;
						left: -20px;
						top: 0;
						border-style: solid;
						border-width: 10px;
						border-color: #FFB800 #FFB800 transparent transparent;
					}
				}
				.img-con{
					width: 112px;
					height: 64px;
				}
				.name{
					font-size: 14px;
					line-height: 20px;
					@include wordhide;
					max-width: 90%;
					margin: 12px 0;
				}
				.price{
					@include Scenter;
					font-size: 15px;
					font-weight: bold;
					.coins{
						margin-right: 9px;
					}
				}
			}
		}
		.replace-btn{
			margin-top: 12px;
		}
	}
	.animate-img{
		width: 200px;
		height: 200px;
		img{
			width: 100%;
			height: 100%;
		}
	}
	.balance-use-con{
		@include Scenter;
		padding: 0 15px;
		.label{
			margin-right: 15px;
			font-size: 12px;
		}
		.el-slider{
			flex: 1;
		}
		.el-slider__bar{
			background: #5B5F72;
		}
		.el-slider__button{
			border: none;
			border-radius: 0;
			// transform: rotateZ(45deg);
			background: url(~@/assets/img/progress-tip.png);
			background-size: 100% 100%;
			transform: scale(2);
		}
		.el-slider__runway{
			background: #292B35;
		}
	}
	.balance-use-show{
		display: flex;
		justify-content: space-between;
		padding: 0 15px;
		margin: 15px 0;
		.price{
			@include Scenter;
			.coins{
				width: 20px;
				height: 20px;
				margin-right: 0;
			}
			span{
				margin: 0 6px;
				color: #bbb;
			}
		}
	}
	.toolbar-poper{
		z-index: 99999 !important;
	}
	.replace-con{
		width: 364px;
		height: 216px;
		margin: 10px auto;
		background-image: url(~@/assets/img/replace-bg.png);
		background-size: 100% 100%;
		.replace-top{
			@include Scenter;
			justify-content: space-between;
			padding: 10px 8px 0;
			margin-bottom: 10px;
			.tip{
				color: #999;
				text-decoration: underline;
				cursor: pointer;
			}
		}
		.replace-main{
			display: flex;
			justify-content: space-between;
			.gun-con{
				width: 96px;
				.gun-empty{
					@include HScenter;
					flex-direction: column;
					height: 134px;
					img{
						height: 53px;
						margin-top: -18px;
					}
					span{
						color: #999;
						font-size: 12px;
					}
				}
				.gun-list{
					height: 134px;
					// @include Hcenter;
					// flex-direction: column;
					.scroll-con{
						height: 100px;
						overflow: hidden;
						margin-top: 5px;
						.el-scrollbar__wrap{
							overflow-x: hidden;
						}
					}
					.img-con{
						width: 50px;
						height: 34px;
						margin: 1px auto;
					}
				}
				.price{
					height: 36px;
					margin-top: 6px;
					padding: 0 5px;
					.coins{

					}
				}
			}
			.rate{
				@include HScenter;
				flex-direction: column;
				width: 123px;
				height: 123px;
				
				span{
					background: var(--logo, linear-gradient(180deg, #448EFD 0%, #44FDE7 100%));
					background-clip: text;
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					text-align: center;
					font-size: 28px;
					font-weight: 700;
					margin-bottom: 6px;
				}
			}
		}
	}
</style>
